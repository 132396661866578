import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { mapToSEO, SEO } from "@components/SEO"
import { Grid } from "semantic-ui-react"
import { mapToCheckoutSelectionCard } from "@mappers/Checkout/CheckoutSelectionCard"
import OrderSummary from "@components/OrderSummary/OrderSummary"
import theme from "@styles/theme"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import PlateDeliveryAddressSelection from "@components/PlateDeliveryAddressSelection/PlateDeliveryAddressSelection"
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector"
import { Dispatch } from "redux"
import { Record } from "immutable"
import { updateDeliveryAddress } from "@redux/actions/checkout"
import { connect } from "react-redux"

import { Address } from "@models/Checkout/Checkout"
import { currentBasketSelector } from "@redux/selectors/basketSelector"
import { mapToCheckoutStep } from "@utils/mapper"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import MobilePlateDeliveryAddressSelection from "@components/Mobile/PlateDeliveryAddressSelection/PlateDeliveryAddressSelection"
import { isMobile } from "@utils/Helpers"
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader"
import MobileGenericBannerContainer from "@components/Mobile/GenericBannerContainer/GenericBannerContainer"

const PlateOwnerSelectionPageContainer = (props: any) => {
  const checkout = getCurrentCheckout()
  let node: any = props.data.allKontentItemPlateOwnerSelectionPage.nodes[0]

  props.data.allKontentItemPlateOwnerSelectionPage.nodes.forEach((item: any) => {
    if (checkout.isDealerOrder) {
      if (item.elements.heading.value == "Dealer Delivery Details") node = item
    } else {
      if (item.elements.heading.value == "Delivery Details") node = item
    }
  });

  const apiUrl = props.data.site.siteMetadata.apiUrl
  const addressValidationToken =
    props.data.site.siteMetadata.addressValidationToken
  const seo = mapToSEO(node.elements)
  const step = mapToCheckoutStep(node.elements)
  const sameDeliveryAddressCard = mapToCheckoutSelectionCard(
    node.elements.plate_owner_card.linked_items[0].elements
  )
  const differentDeliveryAddressCard = mapToCheckoutSelectionCard(
    node.elements.gifting_card.linked_items[0].elements
  )
  const reCaptchaSiteKey = props.data.site.siteMetadata.reCaptchaSiteKey
  const reCaptchaSiteKeyV2 = props.data.site.siteMetadata.reCaptchaSiteKeyV2

  if(isMobile() === undefined){
    return null;
  }

  return (
    <Layout version="simple" currentStep={step} location={props.location}>
      <SEO {...seo} />
      <MobileGenericBannerContainer
        padding={{
          mobile: {
            top: 20,
            bottom: 40,
          },
          desktop: {
            top: 40,
            bottom: 60,
          },
        }}
        backgroundColor={isMobile() ? theme.mobileTheme.bgColor : theme.brand.colors.beige}
      >
        <Grid stackable reversed="mobile tablet vertically" stretched>
          <Grid.Column mobile={16} tablet={16} computer={10}>
            { isMobile() ?
            <MobilePlateDeliveryAddressSelection
              addressValidationToken={addressValidationToken}
              heading={node.elements.heading.value}
              subHeading={node.elements.sub_heading.value}
              checkoutState={props.checkoutState}
              basketState={props.basket}
              body={node.elements.body.value}
              sameAddressCard={sameDeliveryAddressCard}
              differentAddressCard={differentDeliveryAddressCard}
              updateDeliveryAddress={props.updateDeliveryAddress}
              reCaptchaSiteKey={reCaptchaSiteKey}
              reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}
              apiUrl={apiUrl}
            /> :
            <PlateDeliveryAddressSelection
              addressValidationToken={addressValidationToken}
              heading={node.elements.heading.value}
              subHeading={node.elements.sub_heading.value}
              checkoutState={props.checkoutState}
              basketState={props.basket}
              body={node.elements.body.value}
              sameAddressCard={sameDeliveryAddressCard}
              differentAddressCard={differentDeliveryAddressCard}
              updateDeliveryAddress={props.updateDeliveryAddress}
              reCaptchaSiteKey={reCaptchaSiteKey}
              reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}
              apiUrl={apiUrl}
            />}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5} floated="right">
            {isMobile() ?
              <NavHeader link="/personal-details/#vehicle-registration" info="Delivery Details"/>:
              <OrderSummary
                isLayby={false}
                showSurcharge={false}
                basket={props.basket}
                apiUrl={apiUrl}
              ></OrderSummary>}
          </Grid.Column>
        </Grid>
      </MobileGenericBannerContainer>
    </Layout>
  )
}

const mapStateToProps = (state: Record<any>) => {
  const checkout = currentCheckoutSelector(state).toJS()
  const basket = currentBasketSelector(state).toJS()
  return {
    checkoutState: checkout,
    basket: basket,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateDeliveryAddress(deliveryAddress: Address & {
    title: string
    firstName: string
    lastName: string
    sameAsOwnerAddress: boolean
    companyName: string
  }) {
    dispatch(updateDeliveryAddress(deliveryAddress))
  },

})
const PlateOwnerSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlateOwnerSelectionPageContainer)
export default PlateOwnerSelectionPage

export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
        addressValidationToken
        reCaptchaSiteKey
        reCaptchaSiteKeyV2
      }
    }
    allKontentItemPlateOwnerSelectionPage {
      nodes {
        elements {
          body {
            value
          }
          heading {
            value
          }
          seo__nofollow {
            value {
              codename
              name
            }
          }
          seo__noindex {
            value {
              codename
              name
            }
          }
          seo__page_description {
            value
          }
          seo__page_title {
            value
          }
          page_info___checkout__progress {
            value {
              codename
              name
            }
          }
          sub_heading {
            value
          }
          url {
            value
          }
          plate_owner_card {
            linked_items {
              ... on KontentItemCheckoutSelectionCard {
                id
                elements {
                  body {
                    value
                  }
                  heading {
                    value
                  }
                }
              }
            }
          }
          gifting_card {
            linked_items {
              ... on KontentItemCheckoutSelectionCard {
                id
                elements {
                  body {
                    value
                  }
                  heading {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
