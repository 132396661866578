import React, { SyntheticEvent } from "react"
import { H2, Paragraph1, Paragraph2, Legal } from "@styles/Global.styles"
import { Grid, Form } from "semantic-ui-react"
import { CheckoutSelectionCard } from "@components/CheckoutSelectionCard"
import {
  DeliveryAddressSelectionSubHeading,
  DeliveryAddressContainer,
  RequireSignatureParagraph,
} from "./PlateDeliveryAddressSelection.styles"
import { CheckoutSelectionCardModel } from "@models/Checkout/CheckoutSelectionCard"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { Checkout } from "@models/Checkout/Checkout"
import { Formik } from "formik"
import { deliveryAddressValidationSchema } from "./DeliveryAddressFormValidationSchema"
import { navigate } from "gatsby"
import { KpFormErrorMessageLeft, KpFormField, KpFormInputErrorMessage } from "@styles/Global.forms"
import {
  PersonalDetailsManualAddressContainer,
  PersonalDetailsManualAddressContainerHeading,
  PersonalDetailsContainerLink,
  PersonalDetailsButtonContainer,
  FormikContainer,
  AddressSearchTitleSection,
  AddressSearchAutoComplete,
  AddressToggerLink,
  PersonalDetailsTitleDropdown,
} from "@components/PersonalDetailsForm/PersonalDetailsForm.styles"
import { formattedAddress } from "@utils/Helpers"
import { AddressFinderService } from "@services/AddressFinderService"
import { addressResultRender } from "@components/PersonalDetailsForm/PersonalDetailsForm"
import { BasketResponseModel } from "@models/Basket/BasketResponseModel"
import { setBasketAttributes } from "@services/SalesRecoveryService"
import _ from "lodash"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import ReCaptcha from "@utils/reCaptcha"
import { ReCaptchaService } from "@services/ReCaptchaService"
import { ReCaptchaThreshold } from "@utils/Constant"

export type PlateDeliveryAddressSelectionProps = {
  addressValidationToken: string
  heading: string
  subHeading: string
  body: string
  checkoutState: Checkout
  sameAddressCard: CheckoutSelectionCardModel
  differentAddressCard: CheckoutSelectionCardModel
  updateDeliveryAddress: Function
  basketState: BasketResponseModel,
  apiUrl: string
  reCaptchaSiteKey: string
  reCaptchaSiteKeyV2: string
}

export type PlateDeliveryAddressSelectionState = {
  addressResults: Array<any>
  addressValue: string
  searchingAddress: boolean
  reCaptchaToken?: string
  reCaptchaError: string
}

/* eslint-disable jsx-a11y/label-has-associated-control */
class PlateDeliveryAddressSelection extends React.Component<
  PlateDeliveryAddressSelectionProps,
  PlateDeliveryAddressSelectionState
> {
  debouncedFn: any

  constructor(props: PlateDeliveryAddressSelectionProps) {
    super(props)

    this.state = {
      addressResults: [],
      addressValue: this.props.checkoutState.deliveryAddress.fullAddress,
      searchingAddress: false,
      reCaptchaError: ""
    }
  }

  intialStatus = {
    isSubmitting: false,
    isSubmitted: false,
  }

  handleContinueNavigation = (isDealerOrder: boolean) => {
    const deliveryAddress = isDealerOrder ? {
      ...this.props.checkoutState.dealerAddress,
      sameAsOwnerAddress: true,
      companyName: this.props.checkoutState.dealerDetails?.dealerName,
      title: ''
    } : {
      ...this.props.checkoutState.ownerAddress,
      sameAsOwnerAddress: true,
    }

    this.props.updateDeliveryAddress(deliveryAddress)
    navigate("/review-payment/")
  }

  toggleAddressSame = () => {
    const sameAsOwnerAddress = !this.props.checkoutState.deliveryAddress
      .sameAsOwnerAddress
    const deliveryAddress = {
      ...this.props.checkoutState.deliveryAddress,
      sameAsOwnerAddress: sameAsOwnerAddress,
    }
    this.props.updateDeliveryAddress(deliveryAddress)
  }

  toggleManualAddress = (props: any) => {
    const isManualAddress = !this.props.checkoutState.deliveryAddress
      .isManualAddress
    props.setFieldValue("deliveryAddress.isManualAddress", isManualAddress)
    props.setFieldValue("deliveryAddress.unit", "")
    props.setFieldValue("deliveryAddress.city", "")
    props.setFieldValue("deliveryAddress.postCode", "")
    props.setFieldValue("deliveryAddress.street", "")
    props.setFieldValue("deliveryAddress.suburb", "")

    this.setState({
      addressValue: "",
    })

    const deliveryAddress = this.props.checkoutState.deliveryAddress
    deliveryAddress.isManualAddress = isManualAddress
    this.props.updateDeliveryAddress(deliveryAddress)
  }

  submitForm = async (values: any, actions: any, execute: () => Promise<string>, widgetV2: number | undefined) => {
    actions.setStatus({
      isSubmitting: true,
      isSubmitted: false,
    })

    let recaptcha_v2: HTMLElement | null
    if(document){
      recaptcha_v2 = document.getElementById("g-recaptcha_deliveryDetails-v2")
    } else {
      return
    }

    if(recaptcha_v2 && recaptcha_v2.style.display == "block"){
      const token = window.grecaptcha.getResponse(widgetV2)
      ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v2").then(async (response: any) => {
        if (response && response.data.Success)
        {
          this.setState({reCaptchaError: ""})
          this.processSubmit(values)
        } else {
          actions.setStatus({
            isSubmitting: false,
            isSubmitted: false
          })
          this.setState({reCaptchaError: "Please tick checkbox correctly"})
          return false
        }
      })
    } else {
      const token = await execute()
      this.setState({ reCaptchaToken: token })

      ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v3").then(async (response: any) => {
        if (response.status != 200) return 'recaptcha validate error'
        if (response.data.Data && (!response.data.Data.success || response.data.Data.score < ReCaptchaThreshold)) {
          actions.setStatus({
            isSubmitting: false,
            isSubmitted: false,
          })
          if (document && recaptcha_v2){
            recaptcha_v2!.style.display = "block"
          }
          return false
        } else {
          this.processSubmit(values)
        }
      })
    }
  }

  processSubmit = (values: any) => {
    const deliveryAddress = {
      ...values.deliveryAddress,
      sameAsOwnerAddress: this.props.checkoutState.deliveryAddress
        .sameAsOwnerAddress,
    }
    this.props.updateDeliveryAddress(deliveryAddress)

    const payload = this.createPayload(values)
    if (this.props.basketState.basketId) {
      setBasketAttributes(
        this.props.apiUrl,
        this.props.basketState.basketId,
        payload
      )
    }
    navigate("/review-payment/")
  }

  createPayload = (values: any) => {
    let payload=[]

    if (values.manufactureNow && values.ownerIsApplicant){
      payload.push({
        AttributeName: "Checkout.Delivery.FirstName",
        Value: values.deliveryAddress?.firstName,
      },{
        AttributeName: "Checkout.Delivery.LastName",
        Value: values.deliveryAddress?.lastName,
      },{
        AttributeName: "Checkout.Delivery.Title",
        Value: values.deliveryAddress?.title,
      },{
        AttributeName: "Checkout.Delivery.Address3",
        Value: values.deliveryAddress?.street
      },{
        AttributeName: "Checkout.Delivery.City",
        Value: values.deliveryAddress?.city
      },{
        AttributeName: "Checkout.Delivery.PostCode",
        Value: values.deliveryAddress?.postCode
      })
    }

    if(values.deliveryAddress.building && values.deliveryAddress.building.trim()){
      payload.push({
        AttributeName: "Checkout.Delivery.Address1",
        Value: values.deliveryAddress?.building
      })
    }
    if(values.deliveryAddress.unit && values.deliveryAddress.unit.trim()){
      payload.push({
        AttributeName: "Checkout.Delivery.Address2",
        Value: values.deliveryAddress?.unit
      })
    }
    if(values.deliveryAddress.fullAddress && values.deliveryAddress.fullAddress.trim()){
      payload.push({
        AttributeName: "Checkout.Delivery.StreetAddressFull",
        Value: values.deliveryAddress?.fullAddress
      })
    }
    if(values.deliveryAddress.companyName && values.deliveryAddress.companyName.trim()){
      payload.push({
        AttributeName: "Checkout.Delivery.CompanyName",
        Value: values.deliveryAddress?.companyName
      })
    }
    return payload
}

  handleAddressSearchChange = (e: SyntheticEvent, data: any) => {
    this.setState({ addressValue: data.value })
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(this.searchAddress, 500)
    }
    this.debouncedFn()
  }
  searchAddress = () => {
    const input = this.state.addressValue
    this.setState({
      searchingAddress: true,
    })

    AddressFinderService.experianAddressSearch(
      this.props.addressValidationToken,
      input
    ).then((result: any) => {
      const matchedAddresses = result.map((item: any) => ({
        title: item.text,
        id: item.global_address_key,
      }))
      this.setState({
        addressResults: matchedAddresses,
        searchingAddress: false,
      })
    })
  }
  render() {
    const {
      heading,
      subHeading,
      body,
      sameAddressCard,
      differentAddressCard,
      checkoutState,
    } = this.props
    const sameAddress = this.props.checkoutState.deliveryAddress
      .sameAsOwnerAddress

    const checkout = getCurrentCheckout()
    const isDealerOrder = checkout.isDealerOrder
    const dealerAddress = checkout.dealerAddress
    const dealerDetails = checkout.dealerDetails

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <H2>{heading}</H2>
            <DeliveryAddressSelectionSubHeading>
              {subHeading}
            </DeliveryAddressSelectionSubHeading>
            <Grid stretched>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <CheckoutSelectionCard
                  card={sameAddressCard}
                  onSelect={this.toggleAddressSame}
                  isSelected={sameAddress}
                ></CheckoutSelectionCard>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <CheckoutSelectionCard
                  card={differentAddressCard}
                  onSelect={this.toggleAddressSame}
                  isSelected={!sameAddress}
                ></CheckoutSelectionCard>
              </Grid.Column>
            </Grid>
            <RequireSignatureParagraph color="#707070">
              {body}
            </RequireSignatureParagraph>
            {sameAddress && (
              <DeliveryAddressContainer>
                <Paragraph1 className="paragraph1" color="#707070">
                  Your plates will be delivered to:
                </Paragraph1>
                <Paragraph2 color="#707070">
                  {isDealerOrder ? formattedAddress(
                    dealerAddress,
                    dealerDetails?.salesPersonName, dealerDetails?.dealerName
                  ) : formattedAddress(
                    checkoutState.ownerAddress,
                    `${checkoutState.ownerDetails.firstName} ${checkoutState.ownerDetails.lastName}`
                  )}
                </Paragraph2>
              </DeliveryAddressContainer>
            )}
          </Grid.Column>
          <Grid.Column width={16} verticalAlign="bottom">
            <FormikContainer show={!sameAddress}>
            <ReCaptcha action="deliveryDetails" reCaptchaSiteKey={this.props.reCaptchaSiteKey} reCaptchaSiteKeyV2={this.props.reCaptchaSiteKeyV2}>{captcha => (
              <Formik
                initialValues={this.props.checkoutState}
                validationSchema={deliveryAddressValidationSchema}
                initialStatus={this.intialStatus}
                onSubmit={(values: any, actions: any) =>
                  this.submitForm(values, actions, captcha.execute, captcha.widgetV2)
                }
              >
                {props => (
                  <Form onSubmit={props.handleSubmit}>
                    <Grid columns={16}>
                      <Grid.Column width={16}>
                        <Form.Group widths="equal">
                          <KpFormField width={3}>
                            <label>TITLE</label>
                            <PersonalDetailsTitleDropdown
                              fluid
                              selection
                              options={[
                                {
                                  text: "",
                                  value: "",
                                },
                                {
                                  text: "Mr",
                                  value: "Mr",
                                },
                                {
                                  text: "Mrs",
                                  value: "Mrs",
                                },
                                {
                                  text: "Miss",
                                  value: "Miss",
                                },
                                {
                                  text: "Ms",
                                  value: "Ms",
                                },
                                {
                                  text: "Dr",
                                  value: "Dr",
                                },
                              ]}
                              icon="chevron down"
                              id="deliveryAddress.title"
                              name="deliveryAddress.title"
                              disabled={props.status.isSubmitting}
                              value={
                                props.values.deliveryAddress?.title !==
                                  undefined &&
                                props.values.deliveryAddress?.title !== ""
                                  ? props.values.deliveryAddress?.title
                                  : ""
                              }
                              onBlur={props.handleBlur}
                              onChange={(el: any, data: any) => {
                                props.setFieldValue(
                                  "deliveryAddress.title",
                                  data.value
                                )
                              }}
                            />
                          </KpFormField>
                          <KpFormField
                            width={5}
                            error={
                              props.errors.deliveryAddress?.firstName &&
                              props.touched.deliveryAddress?.firstName
                            }
                          >
                            <label>FIRST NAME</label>
                            {props.errors.deliveryAddress?.firstName &&
                              props.touched.deliveryAddress?.firstName && (
                                <KpFormInputErrorMessage>
                                  {props.errors.deliveryAddress?.firstName}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="deliveryAddress.firstName"
                              name="deliveryAddress.firstName"
                              type="text"
                              aria-label="Delivery Address - First Name"
                              data-private="lipsum"
                              disabled={props.status.isSubmitting}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              //defaultValue={props.values.isDealerOrder ? props.values.ownerDetails.firstName : props.values.deliveryAddress?.firstName}
                              value={props.values.deliveryAddress?.firstName}
                            ></input>
                          </KpFormField>
                          <KpFormField
                            width={8}
                            error={
                              props.errors.deliveryAddress?.lastName &&
                              props.touched.deliveryAddress?.lastName
                            }
                          >
                            <label>LAST NAME</label>
                            {props.errors.deliveryAddress?.lastName &&
                              props.touched.deliveryAddress?.lastName && (
                                <KpFormInputErrorMessage>
                                  {props.errors.deliveryAddress?.lastName}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="deliveryAddress.lastName"
                              name="deliveryAddress.lastName"
                              type="text"
                              data-private="lipsum"
                              aria-label="Delivery Address - Last Name"
                              disabled={props.status.isSubmitting}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.deliveryAddress?.lastName}
                            ></input>
                          </KpFormField>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <KpFormField  error={
                                  props.errors.deliveryAddress?.companyName &&
                                  props.touched.deliveryAddress?.companyName
                                }>
                              <label>COMPANY NAME</label>
                              {props.errors.deliveryAddress?.companyName &&
                              props.touched.deliveryAddress?.companyName && (
                                <KpFormInputErrorMessage>
                                  {props.errors.deliveryAddress?.companyName}
                                </KpFormInputErrorMessage>
                              )}
                              <input
                                  id="deliveryAddress.companyName"
                                  name="deliveryAddress.companyName"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Company Name"
                                  disabled={props.status.isSubmitting}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.deliveryAddress?.companyName}
                              ></input>
                          </KpFormField>
                        </Form.Group>
                        {!this.props.checkoutState.deliveryAddress
                          .isManualAddress && (
                          <Form.Group widths="equal">
                            <KpFormField
                              error={
                                props.errors.deliveryAddress?.street &&
                                props.touched.deliveryAddress?.fullAddress
                              }
                            >
                              <AddressSearchTitleSection>
                                <label>ADDRESS</label>
                                <AddressToggerLink
                                  onClick={() =>
                                    this.toggleManualAddress(props)
                                  }
                                >
                                  ENTER ADDRESS MANUALLY
                                </AddressToggerLink>
                                {props.errors.deliveryAddress?.street &&
                                  props.touched.deliveryAddress
                                    ?.fullAddress && (
                                    <KpFormInputErrorMessage>
                                      Please select an address
                                    </KpFormInputErrorMessage>
                                  )}
                              </AddressSearchTitleSection>

                              <AddressSearchAutoComplete
                                autoComplete="new-password"
                                id="deliveryAddress.fullAddress"
                                name="deliveryAddress.fullAddress"
                                loading={this.state.searchingAddress}
                                type="text"
                                aria-label="Delivery Addr - Full Addr"
                                disabled={props.status.isSubmitting}
                                minCharacters={2}
                                onSearchChange={(
                                  event: SyntheticEvent,
                                  data: any
                                ) => {
                                  props.setFieldValue(
                                    "deliveryAddress.fullAddress",
                                    data.value
                                  )
                                  props.setFieldValue(
                                    "deliveryAddress.unit",
                                    ""
                                  )
                                  props.setFieldValue(
                                    "deliveryAddress.city",
                                    ""
                                  )
                                  props.setFieldValue(
                                    "deliveryAddress.postCode",
                                    ""
                                  )
                                  props.setFieldValue(
                                    "deliveryAddress.street",
                                    ""
                                  )
                                  props.setFieldValue(
                                    "deliveryAddress.suburb",
                                    ""
                                  )
                                  this.handleAddressSearchChange(event, data)
                                }}
                                onResultSelect={(
                                  event: SyntheticEvent,
                                  data: any
                                ) => {
                                  this.setState({
                                    addressValue: data.result.title,
                                    searchingAddress: true,
                                  })
                                  props.setFieldValue(
                                    "deliveryAddress.fullAddress",
                                    data.result.title
                                  )
                                  AddressFinderService.experianAddressMetadata(
                                    this.props.addressValidationToken,
                                    data.result.id
                                  ).then((result: any) => {
                                    const unit = result.address_line_2
                                      ? result.address_line_1
                                      : ""
                                    const addressLine1 = result.address_line_2
                                      ? result.address_line_2
                                      : result.address_line_1

                                    props.setFieldValue(
                                      "deliveryAddress.unit",
                                      unit
                                    )
                                    props.setFieldValue(
                                      "deliveryAddress.city",
                                      result.region
                                    )
                                    props.setFieldValue(
                                      "deliveryAddress.postCode",
                                      result.postal_code
                                    )
                                    props.setFieldValue(
                                      "deliveryAddress.street",
                                      addressLine1
                                    )
                                    props.setFieldValue(
                                      "deliveryAddress.suburb",
                                      result.locality
                                    )
                                    this.setState({
                                      addressValue: data.result.title,
                                      searchingAddress: false,
                                    })
                                  })
                                }}
                                showNoResults={false}
                                value={this.state.addressValue}
                                results={this.state.addressResults}
                                resultRenderer={addressResultRender}
                                {...this.props}
                              />
                            </KpFormField>
                          </Form.Group>
                        )}
                        {this.props.checkoutState.deliveryAddress
                          .isManualAddress && (
                          <PersonalDetailsManualAddressContainer>
                            <Grid>
                              <Grid.Column textAlign="left" width={8}>
                                <PersonalDetailsManualAddressContainerHeading>
                                  Manual Address Input
                                </PersonalDetailsManualAddressContainerHeading>
                              </Grid.Column>
                              <Grid.Column textAlign="right" width={8}>
                                <PersonalDetailsContainerLink
                                  onClick={() =>
                                    this.toggleManualAddress(props)
                                  }
                                >
                                  USE ADDRESS LOOKUP
                                </PersonalDetailsContainerLink>
                              </Grid.Column>
                            </Grid>
                            <Form.Group widths="equal">
                              <KpFormField
                                error={
                                  props.errors.deliveryAddress?.building &&
                                  props.touched.deliveryAddress?.building
                                }
                              >
                                <label>BUILDING NAME</label>
                                {props.errors.deliveryAddress?.building &&
                                  props.touched.deliveryAddress?.building && (
                                    <KpFormInputErrorMessage>
                                      {props.errors.deliveryAddress?.building}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="deliveryAddress.building"
                                  name="deliveryAddress.building"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Delivery Address - Building"
                                  disabled={props.status.isSubmitting}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  //defaultValue={props.values.isDealerOrder ? props.values.ownerAddress.building : props.values.deliveryAddress?.building}
                                  value={props.values.deliveryAddress?.building}
                                ></input>
                              </KpFormField>
                              <KpFormField
                                error={
                                  props.errors.deliveryAddress?.unit &&
                                  props.touched.deliveryAddress?.unit
                                }
                              >
                                <label>UNIT #, FLOOR, LEVEL</label>
                                {props.errors.deliveryAddress?.unit &&
                                  props.touched.deliveryAddress?.unit && (
                                    <KpFormInputErrorMessage>
                                      {props.errors.deliveryAddress?.unit}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="deliveryAddress.unit"
                                  name="deliveryAddress.unit"
                                  type="text"
                                  data-private="lipsum"
                                  aria-label="Delivery Address - Unit"
                                  disabled={props.status.isSubmitting}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  //defaultValue={props.values.isDealerOrder ? props.values.ownerAddress.unit : props.values.deliveryAddress?.unit}
                                  value={props.values.deliveryAddress?.unit}
                                ></input>
                              </KpFormField>
                            </Form.Group>
                            <Form.Group widths="equal">
                              <KpFormField
                                error={
                                  props.errors.deliveryAddress?.street &&
                                  props.touched.deliveryAddress?.street
                                }
                              >
                                <label>STREET ADDRESS</label>
                                {props.errors.deliveryAddress?.street &&
                                  props.touched.deliveryAddress?.street && (
                                    <KpFormInputErrorMessage>
                                      {props.errors.deliveryAddress?.street}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="deliveryAddress.street"
                                  name="deliveryAddress.street"
                                  type="text"
                                  data-private="lipsum"
                                  aria-label="Delivery Address - Street"
                                  disabled={props.status.isSubmitting}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  //defaultValue={props.values.isDealerOrder ? props.values.ownerAddress.street : props.values.deliveryAddress?.street}
                                  value={props.values.deliveryAddress?.street}
                                ></input>
                              </KpFormField>
                              <KpFormField
                                error={
                                  props.errors.deliveryAddress?.suburb &&
                                  props.touched.deliveryAddress?.suburb
                                }
                              >
                                <label>SUBURB</label>
                                {props.errors.deliveryAddress?.suburb &&
                                  props.touched.deliveryAddress?.suburb && (
                                    <KpFormInputErrorMessage>
                                      {props.errors.deliveryAddress?.suburb}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="deliveryAddress.suburb"
                                  name="deliveryAddress.suburb"
                                  type="text"
                                  data-private="lipsum"
                                  aria-label="Delivery Address - Suburb"
                                  disabled={props.status.isSubmitting}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  //defaultValue={props.values.isDealerOrder ? props.values.ownerAddress.suburb : props.values.deliveryAddress?.suburb}
                                  value={props.values.deliveryAddress?.suburb}
                                ></input>
                              </KpFormField>
                            </Form.Group>
                            <Form.Group widths="equal">
                              <KpFormField
                                error={
                                  props.errors.deliveryAddress?.city &&
                                  props.touched.deliveryAddress?.city
                                }
                              >
                                <label>CITY OR TOWN</label>
                                {props.errors.deliveryAddress?.city &&
                                  props.touched.deliveryAddress?.city && (
                                    <KpFormInputErrorMessage>
                                      {props.errors.deliveryAddress?.city}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="deliveryAddress.city"
                                  name="deliveryAddress.city"
                                  type="text"
                                  data-private="lipsum"
                                  aria-label="Delivery Address - City"
                                  disabled={props.status.isSubmitting}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  //defaultValue={props.values.isDealerOrder ? props.values.ownerAddress.city : props.values.deliveryAddress?.city}
                                  value={props.values.deliveryAddress?.city}
                                ></input>
                              </KpFormField>
                              <KpFormField
                                error={
                                  props.errors.deliveryAddress?.postCode &&
                                  props.touched.deliveryAddress?.postCode
                                }
                              >
                                <label>POSTCODE</label>
                                {props.errors.deliveryAddress?.postCode &&
                                  props.touched.deliveryAddress?.postCode && (
                                    <KpFormInputErrorMessage>
                                      {props.errors.deliveryAddress?.postCode}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="deliveryAddress.postCode"
                                  name="deliveryAddress.postCode"
                                  type="text"
                                  aria-label="Delivery Address - Postcode"
                                  disabled={props.status.isSubmitting}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  data-private="lipsum"
                                  value={props.values.deliveryAddress.postCode}
                                ></input>
                              </KpFormField>
                            </Form.Group>
                          </PersonalDetailsManualAddressContainer>
                        )}
                      </Grid.Column>
                    </Grid>
                    {this.state.reCaptchaError && <KpFormErrorMessageLeft>{this.state.reCaptchaError}</KpFormErrorMessageLeft>}
                    <div className="g-recaptcha-v2" data-sitekey={this.props.reCaptchaSiteKeyV2} id="g-recaptcha_deliveryDetails-v2" style={{display:"none"}}></div>
                    <PersonalDetailsButtonContainer verticalAlign="bottom">
                      <Grid.Column width={16}>
                        <Grid reversed="mobile vertically">
                          <Grid.Column mobile={16} tablet={8} computer={8}>
                            <KpButton
                              id="pdas-back"
                              fullWidth="mobile"
                              buttonType="secondary"
                              color={theme.brand.colors.black}
                              link="/personal-details/"
                            >
                              BACK
                            </KpButton>
                          </Grid.Column>
                          <Grid.Column
                            mobile={16}
                            tablet={8}
                            computer={8}
                            textAlign="right"
                          >
                            <KpButton
                              id="pdas-next"
                              fullWidth="mobile"
                              loading={props.status.isSubmitting}
                              disabled={this.state.searchingAddress}
                              buttonType="primary"
                              color={theme.brand.colors.blue}
                              type="submit"
                            >
                              CONTINUE
                            </KpButton>
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </PersonalDetailsButtonContainer>
                  </Form>
                )}
              </Formik>
             )}</ReCaptcha>
            </FormikContainer>
          </Grid.Column>
        </Grid.Row>
        {sameAddress && (
          <Grid.Row verticalAlign="bottom">
            <Grid.Column width={16}>
              <Grid reversed="mobile vertically">
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <KpButton
                    id="pdas-back"
                    fullWidth="mobile"
                    buttonType="secondary"
                    color={theme.brand.colors.black}
                    link="/personal-details/"
                  >
                    BACK
                  </KpButton>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  textAlign="right"
                >
                  <KpButton
                    id="pdas-next"
                    fullWidth="mobile"
                    buttonType="primary"
                    color={theme.brand.colors.blue}
                    onClick={() =>this.handleContinueNavigation(isDealerOrder)}
                  >
                    CONTINUE
                  </KpButton>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    )
  }
}

export default PlateDeliveryAddressSelection
